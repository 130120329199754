var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-hover',{attrs:{"open-delay":"200"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{attrs:{"elevation":hover ? 16 : 2,"max-width":"480","max-height":"480"}},[_c('v-card',{attrs:{"flat":"","fluid":""},on:{"click":function($event){_vm.select ? _vm.$emit('select', _vm.test) : _vm.$emit('preview', _vm.test)}}},[(_vm.selectedTests)?_c('v-overlay',{attrs:{"absolute":"","value":_vm.selected(_vm.test)}},[_c('v-icon',{staticClass:"primary--text",attrs:{"x-large":""}},[_vm._v("mdi-check")])],1):_vm._e(),_c('div',{staticStyle:{"height":"150px"}},[_c('v-img',{attrs:{"lazy-src":"https://picsum.photos/id/11/10/6","src":_vm.test.image ? (_vm.apiUrl + "/images/test/" + (_vm.test.image)) : "/teste.jpeg","height":"150px"}},[_c('v-system-bar',{attrs:{"window":"","flat":"","color":"rgba(0, 0, 0, 0)","dense":""}},[(_vm.test.user.id === _vm.currentUser.id)?_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","dark":"","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',{on:{"click":function($event){$event.preventDefault();_vm.$emit('edit', Object.assign({}, _vm.test))}}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('editTest')))])],1),_c('v-list-item',{on:{"click":function($event){return _vm.$emit('delete', _vm.test.id)}}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('remove')))])],1)],1)],1):_vm._e(),(
                _vm.test.entity && _vm.test.creatorType != 'entity' && _vm.test.status
              )?_c('strong',{staticClass:"success--text"},[_vm._v(_vm._s(_vm.$t('approved')))]):(_vm.test.entity && _vm.test.creatorType != 'entity')?_c('strong',{staticClass:"error--text"},[_vm._v(_vm._s(_vm.$t('pending')))]):_vm._e(),_c('v-spacer'),_c('v-chip',{staticClass:"text-capitalize",attrs:{"dark":"","small":"","label":""}},[_vm._v(" "+_vm._s(_vm.$t(_vm.test.type))+" ")])],1)],1)],1),_c('v-card-text',[(_vm.test.title.length >= 36)?_c('div',{staticClass:"body black--text",staticStyle:{"font-size":"12pt"}},[_vm._v(" "+_vm._s(_vm.test.title)+" ")]):_c('div',{staticClass:"body black--text",staticStyle:{"font-size":"12pt"}},[_vm._v(" "+_vm._s(_vm.test.title)+" ")]),_c('div',{staticClass:"my-2 px-0",staticStyle:{"font-size":"12px"}},[_vm._v(" "+_vm._s(_vm.$t('test_by'))+" "+_vm._s(_vm.getUserName(_vm.test.user.name))+" ")]),(_vm.test.description.length > 45)?_c('p',{staticClass:"black--text  py-1",staticStyle:{"font-size":"14px","height":"50px"}},[_vm._v(" "+_vm._s(_vm.test.description ? _vm.test.description.substring(0, 45) + "..." : "")+" ")]):_c('p',{staticClass:"black--text ",staticStyle:{"font-size":"14px","height":"50px"}},[_vm._v(" "+_vm._s(_vm.test.description ? _vm.test.description : "")+" ")])])],1),_c('v-card-actions',[_c('v-row',{staticClass:"d-flex flex-row justify-end align-center pa-5"},[_c('v-btn',{attrs:{"small":"","color":"primary"}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-cart")]),_vm._v(" "+_vm._s(_vm.test.price || _vm.test.price > 0 ? _vm.formatCurrency(_vm.test.price) : _vm.$t('Free'))+" ")],1),_c('v-spacer'),(_vm.select)?_c('v-btn',{attrs:{"color":"primary","text":"","small":""},on:{"click":function($event){return _vm.$emit('preview', _vm.test)}}},[_vm._v(" "+_vm._s(_vm.$t('view'))+" ")]):_c('v-btn',{attrs:{"icon":"","small":""}},[_c('v-icon',{attrs:{"small":"","color":"black"}},[_vm._v("mdi-share-variant")])],1)],1)],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }