import gql from 'graphql-tag'
import testFagment from './TestFragment'

export const ENTITIES_QUERY = gql`
  query Entities {
    entities: entities {
      id
      name
    }
  }
`
export const GET_TESTS_PARTNER_QUERY = gql`
  query GetTestsPartner($filterTest: FilterTest) {
    tests: getTestsPartner(filterTest: $filterTest) {
      tests {
        ...${testFagment}
      }
      totalDocs
      totalPages
    }
  }
`